import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/assets/images/white-beach-shoreline-with-umbrellas.jpg");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/components/core/FormButton/Form.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[locale]/Page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Icon"] */ "/opt/atlassian/pipelines/agent/build/src/components/core/Icon/Icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/components/Menu/_components/Client/ClientMenu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/components/typography/Typography.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/components/core/Loader/Loader.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CookiePopup"] */ "/opt/atlassian/pipelines/agent/build/src/layout/Layout/components/CookiePopup/CookiePopup.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/layout/Layout/components/Footer/Footer.module.scss");
